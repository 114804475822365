import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import Main from "../components/Main";

import { PageHeader, H3 } from "../components/Typo";
import Section from "../components/Section";
import Youtube from "../components/Youtube";
import MediaBottom from "../components/MediaBottom";

import echteMuzikanten from "../images/echtemuzikanten.jpg";
import hof from "../images/hof.jpeg";

import { Ruler } from "../svg";

// eslint-disable-next-line
import { squareImage } from "../image";

const Column = styled.div`
  flex: 1 1 50%;

  ${media.greaterThan("medium")`
    &:first-child {
      margin-right: 64px;
    }
  `}

  ${media.lessThan("medium")`
    &:first-child {
      margin-bottom: 32px;
    }

    & .yt-theater {
      height: 50vw !important;
    }
  `}
`;

// style={{ columns: 2, columnGap: 58, marginTop: 32 }}

const CustomSongTextWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 56px 0px;
  margin-top: 168px;

  ${media.lessThan("medium")`
  margin-top: 96px;

  & img {
    display: none;
  }
  `}
`;

const CustomSongText = styled.div`
  ${media.greaterThan("medium")`
    columns: 2;
    column-gap: 58px;
    margin-top: 32px;
  `}
`;

const Contact = styled.div`
  margin-top: 48px;
  background-color: rgba(109, 190, 159, 0.06);
  color: #294c41;
  font-weight: 300;
  padding: 40px 45px;
  border-radius: 2px;

  ${media.lessThan("medium")`
    margin-top: 24px;
  `}
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "1.jpg" }) {
        ...squareImage
      }
    }
  `);

  return (
    <Main title="Boek" path="/boek">
      <PageHeader center title="Boek" icon="book" />
      <Section flex style={{ marginTop: 96 }}>
        <Column>
          <H3>Huiskamerconcert</H3>
          <p>
            Hiervoor hoef je de deur niet uit! Emma Lou komt bij je langs met
            gitaar, viool en mooie liedjes. Het enige wat jij regelt is een
            aandachtig publiek, een hapje en drankje. Een intieme maar relaxte
            sfeer, dit alles akoestisch uitgevoerd dus de buren hebben er geen
            last van. Helemaal niet als je ze uitnodigt!
          </p>

          <Contact>
            Voor meer informatie en een prijsopgave mail naar info@emmalou.nl of
            stuur een bericht via het{" "}
            <Link to="/contact">contactformulier</Link>.
          </Contact>
        </Column>
        <Column>
          <H3>Theater</H3>
          <p>
            Een avondvullende muziektheatervoorstelling bij u in het theater.
          </p>
          <Youtube
            className="yt-theater"
            videoId="pYXlMrOULao"
            width={"100%"}
            height={250}
          />
        </Column>
      </Section>
      <CustomSongTextWrapper>
        <Section noMargin style={{ position: "relative" }}>
          <img
            alt=""
            src={hof}
            style={{
              position: "absolute",
              left: "calc(50% + 16px)",
              top: -160,
              width: 300,
              transform: "rotate(2deg)",
              outline: "1px solid #f7f7f7",
              border: "6px solid white",
              borderRadius: 1,
              boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Ruler
            width={300}
            style={{
              position: "absolute",
              left: "100%",
              fill: "white",
              top: -20,
              transform: "rotate(-60deg)",
            }}
          />
          <H3>Op je lijf geschreven</H3>

          <CustomSongText>
            <p style={{ marginTop: 0 }}>
              Een lied op je lijf geschreven! Vertel je verhaal verpakt in een
              persoonlijk lied. Ik schrijf het met alle liefde. Speciaal voor
              jullie bruiloft, het verjaardagsfeestje van een goede vriend, het
              jubileum van je bedrijf of elke andere bijzondere gelegenheid.
            </p>

            <p>
              Voordat ik ga schrijven hebben we een gesprek over waar het lied
              over moet gaan en wat je wilt vertellen. Daarna ga ik aan de slag.
              Tussendoor hebben we contact of het voldoet aan jouw wensen en
              stuur ik je de definitieve versie. Op de gelegenheid zelf zing ik
              dit lied begeleid door piano of gitaar en eventueel een mooie
              vioolsolo.
            </p>
          </CustomSongText>
        </Section>
      </CustomSongTextWrapper>
      <Section flex style={{ marginTop: 96 }}>
        <Column>
          <img alt="" src={echteMuzikanten} width="100%" />
        </Column>
        <Column>
          <H3>BRUILOFT, FEEST OF FESTIVAL</H3>
          <p>
            Kleine intieme liedjes of Emma Lou knallend met volle bandbezetting?
            Er is veel mogelijk! Elk optreden is maatwerk. Emma Lou speelt in op
            wat passend is bij de gelegenheid. Neem contact met mij op, dan
            bespreken we je wensen.
          </p>
        </Column>
      </Section>

      <MediaBottom />
    </Main>
  );
};
